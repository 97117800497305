























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { readCreator } from "@/store/creator/getters";
import _ from "lodash";
import { downloadExport, intersect } from "@/utils";
import {
  readCountries,
  readCrews,
  readLanguages,
  readOffices,
} from "@/store/main/getters";
import {
  dispatchCreateCreator,
  dispatchDeleteCreator,
  dispatchUpdateCreator,
} from "@/store/creator/actions";
import { api } from "@/api";
import { readIsManager } from "@/store/auth/getters";
import dayjs from "dayjs";

@Component
export default class CreatorEdit extends Vue {
  public deleting = false;
  public exporting = false;
  public saving = false;

  @Prop({ default: false }) public readonly createView!: boolean;

  public deleted = false;

  public typeOptions = ["Individual", "Organization"];
  public yesNoOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  public firstName: string | null = null;
  public lastName: string | null = null;
  public email: string | null = null;
  public phoneNumber: string | null = null;
  public birthdate: string | null = null;
  public nationality: string | null = null;
  public residenceCountry: string | null = null;
  public residenceCountryDetails: string | null = null;
  public firstLanguage: string | null = null;
  public secondLanguage: string | null = null;
  public countriesOfInterest: string[] | [] = [];
  public brandDealsOfInterest: string | null = null;
  public marketingAllowed: boolean | null = null;
  public terminationScheduled: boolean | null = false;
  public homeAddress: string | null = null;
  public crewId: number | null = null;
  public type: string | null = "Individual";
  public IndonesiaProvinces = [
    { id: 11, nama: "Aceh" },
    { id: 12, nama: "Sumatera Utara" },
    {
      id: 13,
      nama: "Sumatera Barat",
    },
    { id: 14, nama: "Riau" },
    { id: 15, nama: "Jambi" },
    { id: 16, nama: "Sumatera Selatan" },
    {
      id: 17,
      nama: "Bengkulu",
    },
    { id: 18, nama: "Lampung" },
    { id: 19, nama: "Kepulauan Bangka Belitung" },
    {
      id: 21,
      nama: "Kepulauan Riau",
    },
    { id: 31, nama: "Dki Jakarta" },
    { id: 32, nama: "Jawa Barat" },
    {
      id: 33,
      nama: "Jawa Tengah",
    },
    { id: 34, nama: "Di Yogyakarta" },
    { id: 35, nama: "Jawa Timur" },
    { id: 36, nama: "Banten" },
    {
      id: 51,
      nama: "Bali",
    },
    { id: 52, nama: "Nusa Tenggara Barat" },
    { id: 53, nama: "Nusa Tenggara Timur" },
    {
      id: 61,
      nama: "Kalimantan Barat",
    },
    { id: 62, nama: "Kalimantan Tengah" },
    { id: 63, nama: "Kalimantan Selatan" },
    {
      id: 64,
      nama: "Kalimantan Timur",
    },
    { id: 65, nama: "Kalimantan Utara" },
    { id: 71, nama: "Sulawesi Utara" },
    {
      id: 72,
      nama: "Sulawesi Tengah",
    },
    { id: 73, nama: "Sulawesi Selatan" },
    { id: 74, nama: "Sulawesi Tenggara" },
    {
      id: 75,
      nama: "Gorontalo",
    },
    { id: 76, nama: "Sulawesi Barat" },
    { id: 81, nama: "Maluku" },
    {
      id: 82,
      nama: "Maluku Utara",
    },
    { id: 91, nama: "Papua Barat" },
    { id: 94, nama: "Papua" },
  ];

  get creator() {
    return readCreator(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  get countries() {
    return readCountries(this.$store);
  }

  get languages() {
    return readLanguages(this.$store);
  }

  get crews() {
    return readCrews(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }

  public goBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
  }

  public reset() {
    if (this.creator) {
      this.firstName = this.creator.first_name;
      this.lastName = this.creator.last_name;
      this.email = this.creator.email || null;
      this.phoneNumber = this.creator.phone_number || null;
      this.birthdate = this.creator.birthdate || null;
      this.nationality = this.creator.nationality || null;
      this.residenceCountry = this.creator.residence_country || null;
      this.residenceCountryDetails =
        this.creator.residence_country_details || null;
      this.secondLanguage = this.creator.second_language || null;
      this.countriesOfInterest = this.creator.countries_of_interest || [];
      this.brandDealsOfInterest = this.creator.brand_deals_of_interest;
      this.marketingAllowed = this.creator.marketing_allowed;
      this.terminationScheduled = this.creator.termination_scheduled;
      this.homeAddress = this.creator.home_address || null;
      this.crewId = this.creator.crew_id;
      this.type = this.creator.type;
    }
  }

  public creatorForm() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email: (this.email || "").toLowerCase(),
      phone_number: this.phoneNumber,
      birthdate: this.birthdate !== "" ? this.birthdate : null,
      nationality: this.nationality,
      residence_country: this.residenceCountry,
      residence_country_details: this.residenceCountryDetails,
      second_language: this.secondLanguage,
      countries_of_interest: this.countriesOfInterest.toString(),
      brand_deals_of_interest: this.brandDealsOfInterest,
      marketing_allowed: this.marketingAllowed,
      termination_scheduled: this.terminationScheduled,
      home_address: this.homeAddress,
      crew_id: this.crewId,
      type: this.type,
    };
  }

  public edited() {
    if (this.deleted) {
      return false;
    }

    const formObject = this.creatorForm();
    const commonKeys = intersect(formObject, this.creator);
    let filtered = {},
      key;
    for (key in this.creator) {
      if (this.creator.hasOwnProperty(key) && commonKeys.indexOf(key) != -1) {
        filtered[key] = this.creator[key];
      }
    }
    return !_.isEqual(filtered, formObject);
  }

  public confirmDelete() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Please make sure you have removed all managers and accounts associated with this creator first.",
        this["$deleteOptions"]
      )
      .then(async function (dialog) {
        t.deleting = true;
        const response = await dispatchDeleteCreator(t.$store, {
          id: t.creator.id,
        });
        if (response && response.status === 200) {
          t.deleted = true;
          await t.$router.replace(`/manage/creators`);
          t["$toast"].add({
            severity: "success",
            detail: "Creator successfully deleted.",
            life: 3000,
          });
        } else {
          t["$toast"].add({
            severity: "error",
            summary: "Could not delete creator",
            detail:
              "Make sure that the creator is not associated with any accounts before deleting.",
            life: 6000,
          });
        }
        t.deleting = false;
      });
  }

  public confirmSave() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Are you sure you want to save the changes?",
        this["$saveOptions"]
      )
      .then(async function () {
        t.saving = true;
        if (t.createView) {
          const response = await dispatchCreateCreator(t.$store, {
            data: t.creatorForm(),
          });
          if (response) {
            const newId = response.id;
            await t.$router.push(`/manage/creators/${newId}`);
            t["$toast"].add({
              severity: "success",
              detail: "Creator successfully added.",
              life: 3000,
            });
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not create creator",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        } else {
          const response = await dispatchUpdateCreator(t.$store, {
            id: t.creator.id,
            data: t.creatorForm(),
          });
          if (response) {
            t["$toast"].add({
              severity: "success",
              detail: "Creator successfully updated.",
              life: 3000,
            });
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not update creator",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        }
        t.saving = false;
      });
  }

  public remindRequired() {
    const failed = this.$refs["validation-observer"]["flags"]["failed"];
    if (failed) {
      this["$toast"].add({
        severity: "error",
        summary: "Form not valid",
        detail:
          "Make sure to fill out required fields and follow the correct formats.",
        life: 5000,
      });
    }
  }

  public async doExport() {
    this.exporting = true;
    const response = await api.exportPage("creators", [this.creator.id]);
    if (response) {
      const date = dayjs().format("YYYY-MM-DD-HH-mm-ss");
      downloadExport(response, `creators-export-${date}.csv`);
      this["$toast"].add({
        severity: "success",
        summary: "File exported!",
        detail:
          "Import to Google Sheets or open with a text editor for optimal operations",
        life: 3000,
      });
    } else {
      this["$toast"].add({
        severity: "error",
        summary: "Something went wrong...",
        detail: "Could not export your file",
        life: 3000,
      });
    }
    this.exporting = false;
  }
}
